import { DeleteFilled } from "@ant-design/icons";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getFile } from "../../../util";

export const ImageList = ({ setGalleryState, galleryType, galleryState }) => {
  // drag handler
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = galleryState[galleryType].splice(source.index, 1);
    galleryState[galleryType].splice(destination.index, 0, removed);
  };

  //remove image from list
  const onRemove = (url) => {
    const imgs = galleryState[galleryType].filter(imgUrl=>url!==imgUrl);
    setGalleryState({...galleryState, [galleryType]: imgs})
  };


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="d-flex"
          >
            {galleryState[galleryType]?.map((url, index) => (
              <Draggable key={url} draggableId={url} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className="gallery-img" key={url}>
                      <img
                        alt="ddf"
                        style={{
                          width: "100px",
                          aspectRatio: "5/7",
                          objectFit: "cover",
                        }}
                        src={getFile(url)}
                      />
                      <div className="action-img">
                        <DeleteFilled
                          className="delete-icon"
                          onClick={() => onRemove(url)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const getItemStyle = (_, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });