import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Spin, Switch, Upload, message } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
// import SingleCrop from "./SingleCrop";
import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { FILE_UPLOAD } from "../../../graphql/modules";
import { ImageList } from "./ImageList";
import { catSubType } from "../../../config";

export const ColorsGallery = ({galleryState, setGalleryState, sizeType }) => {
  // state declare

  const [galleryImageMode, setGalleryImageMode] = useState(true);

    // product type
    const galleryType =
    sizeType === catSubType.fullSet
      ? "gallery"
      : sizeType?.toLowerCase() + "Gallery";

  // image upload api
  const [SingleUplaodMutation, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);

  const handleUploadGallery = async (e) => {
    const MAX_LENGTH = 4 - galleryState[galleryType].length;
    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      message.warning("Cannot upload files more than 4");
      return;
    }
    const { files = [] } = await e.target;
    let images = [...galleryState[galleryType]] || [];

    for (var i = 0; i < files.length; i++) {
      try {
        // const compressedFile = await imageCompression(files[i], options);
        const {
          data: { SingleUpload },
        } = await SingleUplaodMutation({
          variables: {
            file: files[i],
          },
        });
        if (SingleUpload.success) {
          images.push(SingleUpload.filename);
          setGalleryState({ ...galleryState, [galleryType]: images });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };


  return (
    <Row gutter={[16, 16]}>
      <Col lg={24}>
        <Spin spinning={uploadLoading}>
          <h5>Gallery</h5>
          <small>
            Switch to {galleryImageMode ? "crop" : "multiple"} image uplaod
          </small>
          <Switch
            style={{ marginLeft: "5px" }}
            size="small"
            checked={galleryImageMode}
            onClick={(check) => setGalleryImageMode(check)}
          />
          <div className="d-flex" style={{ marginTop: "10px" }}>
            <ImageList
              galleryState={galleryState}
              galleryType={galleryType}
              setGalleryState={setGalleryState}
            />

            {galleryState[galleryType]?.length < 4 &&
              (!galleryImageMode ? (
                <ImgCrop
                  rotationslider={true}
                  showGrid
                  quality={1}
                  onModalOk={(data) =>
                    handleUploadGallery({ target: { files: [data] } })
                  }
                  aspect={5 / 7}
                >
                  <Upload
                    showUploadList={false}
                    className="upload-picture"
                    listType="picture-card"
                  >
                    <UploadOutlined /> Upload
                  </Upload>
                </ImgCrop>
              ) : (
                <div className="d-inline-block">
                  <input
                    type="file"
                    onChange={handleUploadGallery}
                    className="upload-product-image"
                    multiple
                    accept="image/*"
                  />
                </div>
              ))}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};
